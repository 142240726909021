@import './print.css';
@import './sticky-table.css';

body {
  margin: 0;
  font-size: 1.15rem;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin-bottom: initial;
}

audio {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.form-select.is-invalid > * {
  border-color: #dc3545;
}

.form-select.is-valid > * {
  border-color: #28a745;
}

.Toastify__toast-body {
  white-space: pre-line;
}

::placeholder {
  color: #bbb !important;
}

.bg-black {
  background: black;
}

.large {
  font-size: 125%;
}

.fit {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.lecture-icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.lecture-type-30MinutesBike {
  color: #ab9500;
}

.lecture-type-balanceTraning {
  color: #157c99;
}

/* NOTE: bootstrap 5の先取り */
.border-1 {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.border-3 {
  border-width: 3px;
}
.border-4 {
  border-width: 4px;
}
.border-5 {
  border-width: 5px;
}
.rounded-1 {
  border-radius: .1rem!important;
}
.rounded-2 {
  border-radius: .2rem!important;
}
.rounded-3 {
  border-radius: .3rem!important;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 1rem;
}
.gap-4 {
  gap: 1.5rem;
}
.gap-5 {
  gap: 3rem;
}

.popover {
  z-index: 1050 !important;
}

/* 個別 */
.user-page .form-control {
  font-size: 1.15rem;
}
