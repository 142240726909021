.stepflow {
  font-size: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
}
.stepflow > li {
  position: relative;
  display: block;
  width: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  text-overflow: ellipsis;
  color: #b0bec5;
   -ms-flex: 1;
  -moz-flex: 1;
  -webkit-box-flex: 1;
       flex: 1;
}
.stepflow > li .bubble {
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 auto 0.5em;
  border-radius: 1000px;
  background-color: #b0bec5;
}
.stepflow > li .bubble::after,
.stepflow > li .bubble::before {
  position: absolute;
  top: 9px;
  display: block;
  width: 100%;
  height: 3px;
  content: '';
  background-color: #b0bec5;
}
.stepflow > li.completed,
.stepflow > li.completed .bubble {
  color: #00acc1;
}
.stepflow > li.completed .bubble,
.stepflow > li.completed .bubble::after,
.stepflow > li.completed .bubble::before {
  background-color: #4dd0e1;
}
.stepflow > li:last-child .bubble::after,
.stepflow > li:last-child .bubble::before {
  width: 0%;
  margin-right: 50%;
}
.stepflow > li.active,
.stepflow > li.active .bubble {
  font-weight: bold;
  color: #f57c00;
}
.stepflow > li.active .bubble,
.stepflow > li.active .bubble::after,
.stepflow > li.active .bubble::before {
  background-color: #fb8c00;
}

