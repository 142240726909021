@media print {
  .header-nav,
  .btn,
  .unprint {
    display: none;
  }
  textarea.form-control,
  input.form-control {
    padding: 0;
    border: none;
    color: initial;
  }
  textarea {
    resize: none;
  }
}
