@mixin stretched-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.app-rte {
  h4 {
    font-size: 1.1rem;
    text-indent: 34px;
    position: relative;
    font-weight: 400;
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      margin: 0 0 0 -34px;
      top: 0.25rem;
      width: 24px;
      height: 15px;
      background: url(./images/btn_icon_re.png) center / auto 100% no-repeat;
    }
    a {
      color: initial;
      &:after {
        @include stretched-link
      }
    }
  }
  h5 {
    font-size: 1.3rem;
    position: relative;
    text-align: center !important;
    padding: 15px 20px 18px 70px;
    line-height: 1.4;
    letter-spacing: .01em;
    text-align: center;
    font-weight: 400;
    color: #000 !important;
    border-radius: 15px;
    background: #F3F3EF;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0.95rem;
      left: 1.5rem;
      width: 30px;
      height: 30px;
      background: url(./images/btn_icon_links.png);
      background-size: 28px 28px;
      background-position: 5% 50%;
      background-repeat: no-repeat;
    }
    a {
      color: initial;
      &:after {
        @include stretched-link
      }
    }
    * {
      text-align: center !important;
    }
  }
  img {
    max-width: 100%;
  }
  video {
    max-width: 100%;
  }
  span[class^="ImageSpan"] {
    width: 100% !important;
    height: 100px !important;
    line-height: initial !important;
    font-size: initial !important;
    letter-spacing: initial !important;
    background-size: contain !important;
  }
}
