.sticky-table thead {
  /* 縦スクロール時に固定する */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* tbody内のセルより手前に表示する */
  z-index: 2;
}
.sticky-table .sticky {
  /* 横スクロール時に固定する */
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
.sticky-table .sticky-right {
  right: 0;
}
.sticky-table thead .sticky {
  /* ヘッダー行内の他のセルより手前に表示する */
  z-index: 3;
}

.vertical-sticky-table thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 150;
  z-index: 1;
}
