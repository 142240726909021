.eyecatch-loop {
  width: 100%;
  height: 400px; /* 画像の高さを指定 */
  position: relative;
  background-size: auto 100%;
  animation: bg-slider 40s linear infinite; /* 23sの部分背景画像の約数だとスムーズ */
}
@keyframes bg-slider {
  from { background-position: 0 0; }
    to { background-position: -1518px 0; } /* 1518pxとは使用した背景画像の長さ */
}

